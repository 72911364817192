import { CreateShelfDocument, CustomShelvesDocument,
// MyCustomShelvesDocument,
Shelf, User } from "@/graphql/generated/types";
import { Button } from "../ui/button";
import { useMutation } from "@apollo/client";
import React from "react";
import { cn } from "@/lib/utils";
import { Input } from "../ui/input";
import { Loader2 } from "lucide-react";
import {
// useShelvesStore,
useStatusShelvesStore } from "@/store/useStatusShelvesStore";
import ModifyShelfDrawer from "./ModifyShelfDrawer";
const AddNewShelfInput = ({
  variant = "default",
  onAddShelf,
  username,
  onCancel,
  inputVariant = "default"
}: {
  onCancel?: () => void;
  variant?: "default" | "dialog";
  username: User["username"];
  onAddShelf: (shelf: Shelf) => void;
  inputVariant?: "default" | "mobile";
}) => {
  const [newShelfName, setNewShelfName] = React.useState("");
  // const [tempNewShelfName, setTempNewShelfName] = React.useState("")
  const [errorMessage, setErrorMessage] = React.useState("");
  // const addShelf = useShelvesStore((state) => state.addShelf)
  const setStatusShelves = useStatusShelvesStore(state => state.setShelves);
  const myStatusShelves = useStatusShelvesStore(state => state.shelves);
  const [createShelf, {
    loading
  }] = useMutation(CreateShelfDocument, {
    onCompleted: data => {
      if (!data?.createShelf) return;
      // addShelf(data?.createShelf)
      onAddShelf(data?.createShelf);
      // router.replace(`/${username}/library?shelf=${data?.createShelf?.slug}`)
    },
    onError: error => {
      if (error.graphQLErrors?.[0]?.extensions?.code === "VALIDATION_ERROR") {
        setErrorMessage("Shelf already exists");
      }
      setErrorMessage(error?.message || "Something went wrong.");
    }
  });
  const handleCreateShelf = async (shelfName: string) => {
    if (!shelfName.trim()) {
      setErrorMessage("Shelf name cannot be empty.");
      return;
    }

    // setTempNewShelfName(shelfName)
    setNewShelfName("");
    setErrorMessage("");
    const newShelf = await createShelf({
      variables: {
        name: shelfName
      },
      optimisticResponse: {
        __typename: "RootMutationType",
        createShelf: {
          __typename: "Shelf",
          id: "temp-id-" + Math.random().toString(36).slice(2, 9),
          name: shelfName,
          slug: shelfName.toLowerCase().replace(/\s+/g, "-"),
          userId: "temp-userId-" + Math.random().toString(36).slice(2, 9),
          booksCount: 0
        }
      },
      refetchQueries: username ? [{
        query: CustomShelvesDocument,
        variables: {
          username: username as string
        }
      }] : [],
      awaitRefetchQueries: true
      // update: (cache, { data: mutationData }) => {
      //   if (!mutationData?.createShelf) return

      //   const newShelf = mutationData.createShelf

      //   // Write the new shelf to the cache
      //   const newShelfRef = cache.writeFragment({
      //     data: newShelf,
      //     fragment: gql`
      //       fragment NewShelf on Shelf {
      //         id
      //         name
      //         slug
      //         userId
      //         booksCount
      //         __typename
      //       }
      //     `,
      //   })

      //   cache.modify({
      //     id: cache.identify({ __typename: "User", id: newShelf.userId }),
      //     fields: {
      //       shelves(existingShelvesRefs = []) {
      //         if (
      //           existingShelvesRefs.some(
      //             (ref: Reference) => ref.__ref === cache.identify(newShelf)
      //           )
      //         ) {
      //           return existingShelvesRefs
      //         }

      //         console.log("sssassa", [newShelfRef, ...existingShelvesRefs])
      //         return [newShelfRef, ...existingShelvesRefs]
      //       },
      //     },
      //   })

      //   console.log(cache.extract())
      //   console.log(cache.identify(newShelf))
      // },
    });
    if (!newShelf?.data?.createShelf) return;
    setStatusShelves([newShelf?.data?.createShelf, ...(myStatusShelves || [])]);
  };
  if (inputVariant === "mobile") return <ModifyShelfDrawer onCancel={onCancel} variant={variant} onSave={handleCreateShelf} />;
  return <form onSubmit={e => {
    e.preventDefault();
    handleCreateShelf(newShelfName);
  }} data-sentry-component="AddNewShelfInput" data-sentry-source-file="AddNewShelfInput.tsx">
      <div className={cn("flex mb-6 items-center h-9 w-full", errorMessage && "mb-2")}>
        <Input type="text" value={newShelfName} placeholder="Type shelf name..." className="flex-1 px-4 py-2 bg-white/[6%] rounded-l-[8px] rounded-r-none outline-none border-none placeholder:text-sm text-sm text-white placeholder:text-white/40 h-full" onChange={e => setNewShelfName(e.target.value)} data-sentry-element="Input" data-sentry-source-file="AddNewShelfInput.tsx" />
        <Button disabled={loading || !newShelfName} type="submit" variant="primary" className="max-w-[53px] py-2.5 px-[15px] disabled:bg-[#5069ce] disabled:text-white disabled:opacity-80 max-h-full font-bold text-xs rounded-l-none rounded-r-[8px]"
      // onClick={() => handleCreateShelf(newShelfName)}
      data-sentry-element="Button" data-sentry-source-file="AddNewShelfInput.tsx">
          {loading ? <Loader2 className="animate-spin" /> : "Add"}
        </Button>
      </div>
      {errorMessage && <span className="text-red-400 text-sm">{errorMessage}</span>}
    </form>;
};
export default AddNewShelfInput;