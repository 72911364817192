"use client";

import React, { useState } from "react";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/popover";
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/drawer";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { PopoverArrow } from "@radix-ui/react-popover";
import { useResponsive } from "@/hooks/useResponsive";
import { useStatusShelvesStore } from "@/store/useStatusShelvesStore";
import { AddBooksToShelfDocument, BooksByCustomShelfSlugDocument, ReadingStatusEnum, RemoveBooksFromShelfDocument, Shelf, User } from "@/graphql/generated/types";
import { useMutation } from "@apollo/client";
import AddNewShelfInput from "../library/AddNewShelfInput";
import { PERMANENT_SHELVES } from "@/constants/PERMANENT_SHELVES";
import useSetReadingStatus from "@/hooks/useSetReadingStatus";
import { cn } from "@/lib/utils";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog";
import { handleShelfModification } from "@/utils/updateShelfUtils";
import { useUser } from "@/contexts/user";
import { useSearchParams } from "next/navigation";
import { LIBRARY_BOOKS_PER_PAGE } from "@/constants";
import { useShelfLabel } from "@/hooks/useShelfLabel";
import useLibraryStore from "@/store/useLibraryStore";

// Types you might have
// interface StatusOption {
//   value: string
//   label: string
//   icon?: React.ReactNode
// }

// interface Shelf {
//   id: string
//   name: string
// }

interface StatusShelvesDropdownProps {
  trigger: React.ReactNode;
  initialStatus?: ReadingStatusEnum | null;
  onStatusChange?: (status: ReadingStatusEnum, selectedShelves: string[], unselectedShelves: string[]) => void;
  initialShelves?: Shelf[];
  bookIds: string[];
  commitOnSelection?: boolean;
  variant?: "defauly" | "library";
  shelfSelectionVariant?: "checkbox" | "radio";
  username?: User["username"];
  // statuses: StatusOption[]
  // shelves: Shelf[]
  // onSubmit: (selectedStatus: string | null, selectedShelves: string[]) => void
  onCancel?: () => void;
}
export function StatusShelvesDropdown({
  trigger,
  initialStatus = null,
  onStatusChange,
  commitOnSelection = true,
  initialShelves = [],
  bookIds,
  username,
  // statuses,
  // onSubmit,
  shelfSelectionVariant = "checkbox",
  onCancel
}: StatusShelvesDropdownProps) {
  const {
    isMobile
  } = useResponsive();
  const [open, setOpen] = useState(false);
  const initialSelectedShelves = initialShelves.map(sh => sh.id);
  const statuses = PERMANENT_SHELVES.slice(1);
  const shelves = useStatusShelvesStore(state => state.shelves);
  const [selectedShelves, setSelectedShelves] = useState<string[]>(initialSelectedShelves);
  const {
    user
  } = useUser();
  const [activeTab, setActiveTab] = useState("status");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = React.useState(initialStatus);
  const [unSelectedShelves, setUnselectedShelves] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showNewShelfInput, setShowNewShelfInput] = React.useState(false);
  const [addbooksToShelf] = useMutation(AddBooksToShelfDocument);
  const [removeBooksFromShelf] = useMutation(RemoveBooksFromShelfDocument);
  const {
    setStatus: setReadingStatus
  } = useSetReadingStatus(username as string, bookIds as string[]);
  const setIsRefetchingBooks = useLibraryStore(state => state.setIsRefetchingBooks);
  const currentPage = Number(useSearchParams().get("page")) || 1;
  const currentShelf = useSearchParams().get("shelf") || "ALL";
  const getSelectedShelfSlug = (shelfId: string) => {
    if (!shelves || !shelfId) return "";
    const shelf = shelves?.find(sh => sh.id === shelfId);
    if (shelf) {
      return shelf.slug;
    }
  };
  const selectedShelfSlug = getSelectedShelfSlug(selectedShelves[0]);
  const {
    shelfId: currentShelfId,
    isCustomShelf
  } = useShelfLabel();
  const isPermanentShelf = (sh: string) => PERMANENT_SHELVES.find(shelf => shelf.value === sh) as (typeof PERMANENT_SHELVES)[1] | undefined;
  React.useEffect(() => {
    setSelectedStatus(initialStatus);
  }, [initialStatus]);
  const handleCancel = () => {
    setSelectedStatus(initialStatus);
    setSelectedShelves(initialSelectedShelves);
    setOpen(false);
    if (onCancel) onCancel();
  };
  const handleApply = async () => {
    if (!commitOnSelection) {
      onStatusChange?.(selectedStatus as ReadingStatusEnum, selectedShelves, unSelectedShelves);
      setOpen(false);
      return;
    }
    if (onStatusChange) {
      onStatusChange(selectedStatus ? selectedStatus as ReadingStatusEnum : ReadingStatusEnum.WantToRead, selectedShelves, unSelectedShelves);
    }
    setOpen(false);
    if (currentShelf && currentShelf !== "ALL") {
      setIsRefetchingBooks(true);
    }
    try {
      if (bookIds.length === 1) await setReadingStatus(selectedStatus ? selectedStatus as ReadingStatusEnum : ReadingStatusEnum.WantToRead);else {
        if (selectedStatus) {
          await setReadingStatus(selectedStatus as ReadingStatusEnum);
        }
      }
      if (selectedShelves.length > 0) await addbooksToShelf({
        variables: {
          bookIds: bookIds,
          shelfIds: selectedShelves
        },
        awaitRefetchQueries: true,
        refetchQueries: !isPermanentShelf(currentShelf) && !isPermanentShelf(selectedShelfSlug as string) ? [{
          query: BooksByCustomShelfSlugDocument,
          variables: {
            username: username as string,
            slug: currentShelf,
            page: currentPage,
            pageSize: LIBRARY_BOOKS_PER_PAGE
          }
        }, {
          query: BooksByCustomShelfSlugDocument,
          variables: {
            username: username as string,
            slug: selectedShelfSlug,
            page: currentPage,
            pageSize: LIBRARY_BOOKS_PER_PAGE
          }
        }] : !isPermanentShelf(selectedShelfSlug as string) ? [{
          query: BooksByCustomShelfSlugDocument,
          variables: {
            username: username as string,
            slug: selectedShelfSlug,
            page: currentPage,
            pageSize: LIBRARY_BOOKS_PER_PAGE
          }
        }] : [],
        optimisticResponse: {
          __typename: "RootMutationType",
          addBooksToShelves: {
            __typename: "ShelfBooksUpdateResponse",
            success: true,
            booksAffected: bookIds.length,
            shelvesAffected: selectedShelves.length
          }
        },
        update: (cache, {
          data
        }) => {
          if (!data?.addBooksToShelves?.success) return;
          if (selectedShelves.length === 0) return;
          handleShelfModification(cache, bookIds, user?.id as string, selectedShelves, "add", currentShelf, currentPage);
        }
      });
      if (unSelectedShelves.length > 0) await removeBooksFromShelf({
        variables: {
          bookIds: bookIds,
          shelfIds: unSelectedShelves
        },
        awaitRefetchQueries: true,
        refetchQueries: isCustomShelf ? [{
          query: BooksByCustomShelfSlugDocument,
          variables: {
            username: username as string,
            slug: currentShelf,
            page: currentPage,
            pageSize: LIBRARY_BOOKS_PER_PAGE
          }
        }] : [],
        optimisticResponse: {
          removeBooksFromShelves: {
            booksAffected: bookIds.length,
            shelvesAffected: unSelectedShelves.length,
            success: true,
            __typename: "ShelfBooksUpdateResponse"
          }
        },
        update: (cache, {
          data
        }) => {
          if (!data?.removeBooksFromShelves?.success) return;
          if (unSelectedShelves.length === 0) return;
          handleShelfModification(cache, bookIds, user?.id as string, unSelectedShelves, "remove", currentShelf, currentPage);
        }
      });
      setIsRefetchingBooks(false);
    } catch (e) {
      console.log(e);
      if (e instanceof Error) {
        console.log("error", e);
        // toast.error(e.message, {
        //   style: {
        //     backgroundColor: "#ff4d4f",
        //     color: "white",
        //     fontSize: "16px",
        //   },
        // })
      }
      setSelectedStatus(initialStatus || null);
      setIsRefetchingBooks(false);
    }
    setOpen(false);
    setIsRefetchingBooks(false);
  };
  const filteredShelves = shelves?.filter(sh => sh.name.toLowerCase().includes(searchQuery.toLowerCase()));
  const handleShelfCheckChange = (shelfId: string, checked: boolean) => {
    if (shelfSelectionVariant === "radio") {
      // Radio variant: only one shelf can be checked
      if (!checked) {
        // unchecking the currently selected shelf => no selection
        setSelectedShelves([]);
        setUnselectedShelves(prev => [...prev, shelfId]);
      } else {
        // set the new shelf as the one selected
        setSelectedShelves([shelfId]);
        if (isCustomShelf) setUnselectedShelves([currentShelfId as string]);
        // unselect everything else
        // const others =
        //   shelves?.map((s) => s.id).filter((id) => id !== shelfId) ?? []
        // setUnselectedShelves(others)
      }
    } else {
      // Checkbox variant: multi-select
      if (checked) {
        setSelectedShelves(prev => [...prev, shelfId]);
        setUnselectedShelves(prev => prev.filter(id => id !== shelfId));
      } else {
        setSelectedShelves(prev => prev.filter(id => id !== shelfId));
        setUnselectedShelves(prev => [...prev, shelfId]);
      }
    }
  };
  const content = <Tabs
  // defaultValue="status"
  value={activeTab} onValueChange={setActiveTab} className="flex  flex-col h-auto bg-[#10111e] sm:bg-[#1d1e2f] text-white rounded-md border border-[#bdbdbd]/10">
      <div className="w-full max-sm:flex max-sm:justify-between max-sm:items-center max-sm:pr-5 sm:bg-gradient-to-r p-2 sm:from-[#1d1e2f] sm:to-[#5c5f95]/20">
        <TabsList className="flex ml-1.5 max-w-[128px] space-x-6 sm:space-x-4 p-0 bg-transparent rounded-t-md">
          <TabsTrigger value="status" className="flex-1 px-0 border-b-[2px] border-b-transparent data-[state=active]:border-b-[#94A8F8] text-base font-bold text-white sm:text-sm sm:font-medium hover:bg-[#10111e]/50 data-[state=active]:bg-transparent">
            Status
          </TabsTrigger>
          <TabsTrigger value="shelves" className="flex-1 px-0 border-b-[2px] border-b-transparent data-[state=active]:border-b-[#94A8F8] text-base font-bold text-white sm:text-sm sm:font-medium hover:bg-[#10111e]/50 data-[state=active]:bg-transparent">
            Shelves
          </TabsTrigger>
        </TabsList>

        {isMobile && activeTab === "shelves" ? <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <DialogTrigger asChild>
              <Button
          // onClick={() => setShowNewShelfInput(true)}
          type="button" variant="link" className="p-0 text-[#7187E1] sm:hidden flex items-center gap-1.5 underline">
                Add Shelf{" "}
                {!showNewShelfInput && <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8" r="8" fill="#5069CE" />
                    <path d="M8.71429 3.71429C8.71429 3.52485 8.63903 3.34316 8.50508 3.20921C8.37112 3.07526 8.18944 3 8 3C7.81056 3 7.62888 3.07526 7.49492 3.20921C7.36097 3.34316 7.28571 3.52485 7.28571 3.71429V7.28571H3.71429C3.52485 7.28571 3.34316 7.36097 3.20921 7.49492C3.07526 7.62888 3 7.81056 3 8C3 8.18944 3.07526 8.37112 3.20921 8.50508C3.34316 8.63903 3.52485 8.71429 3.71429 8.71429H7.28571V12.2857C7.28571 12.4752 7.36097 12.6568 7.49492 12.7908C7.62888 12.9247 7.81056 13 8 13C8.18944 13 8.37112 12.9247 8.50508 12.7908C8.63903 12.6568 8.71429 12.4752 8.71429 12.2857V8.71429H12.2857C12.4752 8.71429 12.6568 8.63903 12.7908 8.50508C12.9247 8.37112 13 8.18944 13 8C13 7.81056 12.9247 7.62888 12.7908 7.49492C12.6568 7.36097 12.4752 7.28571 12.2857 7.28571H8.71429V3.71429Z" fill="white" />
                  </svg>}
              </Button>
            </DialogTrigger>
            <DialogContent onOpenAutoFocus={e => e.preventDefault()} dialogCloseClassName="top-[26px] right-6" className="bg-[#10111e] max-w-[348px] border-none rounded-[20px]">
              <DialogHeader className="border-b w-full pb-5 border-b-black flex justify-start items-center">
                <DialogTitle className="text-2xl w-full text-white justify-start font-bold text-left">
                  Add new shelf
                </DialogTitle>
              </DialogHeader>
              <AddNewShelfInput username={username} inputVariant="mobile" onCancel={() => setDialogOpen(false)} variant="dialog" onAddShelf={shelf => {
            setSelectedShelves(prev => [shelf.id, ...prev]);
            setDialogOpen(false);
          }} />
            </DialogContent>
          </Dialog> : null}
      </div>

      {/* Status Content */}
      <TabsContent value="status">
        <RadioGroup value={selectedStatus || ""} onValueChange={val => setSelectedStatus(val as ReadingStatusEnum)} className="gap-0 p-0 sm:divide-y sm:divide-[#bdbdbd]/10">
          {statuses.map(s => <div key={s.value} className="flex items-center hover:bg-[#10111e]/50 sm:px-3.5 gap-2.5">
              <label key={s.value} htmlFor={s.value} className="flex text-sm w-full h-full py-[9px] max-sm:px-2 sm:py-4 font-medium items-center space-x-2 hover:bg-[#10111e]/50 rounded-md cursor-pointer">
                <RadioGroupItem value={s.value} id={s.value} className="size-[18px] hidden sm:inline-block" />

                <div className="
                 sm:hidden      
                w-[42px] h-[42px]
                flex items-center justify-center
                rounded-full
                bg-white/[4%]
                transition-colors
                data-[state=checked]:bg-[#5069ce]
              " data-state={selectedStatus === s.value ? "checked" : "unchecked"}>
                  {selectedStatus === s.value && s.selectedIcon && isMobile ? s.selectedIcon : s.icon}
                </div>
                {s.icon && <div className="hidden sm:block">{s.icon}</div>}
                <span className="text-sm font-medium">{s.label}</span>
              </label>
            </div>)}
        </RadioGroup>
      </TabsContent>

      {/* Shelves Content */}
      <TabsContent value="shelves">
        <div className="flex flex-col h-auto  sm:bg-[#1d1e2f] max-sm:border-none text-white rounded-md">
          <div className="p-3 relative ">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute max-sm:hidden pointer-events-none left-5 top-1/2 -translate-y-1/2">
              <path d="M6.41667 11.0833C8.994 11.0833 11.0833 8.994 11.0833 6.41667C11.0833 3.83934 8.994 1.75 6.41667 1.75C3.83934 1.75 1.75 3.83934 1.75 6.41667C1.75 8.994 3.83934 11.0833 6.41667 11.0833Z" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M12.2484 12.2523L9.71094 9.71484" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="sm:hidden absolute pointer-events-none left-5 top-1/2 -translate-y-1/2">
              <path fillRule="evenodd" clipRule="evenodd" d="M4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C11.8487 18 13.551 17.3729 14.9056 16.3199L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L16.3199 14.9056C17.3729 13.551 18 11.8487 18 10C18 5.58172 14.4183 2 10 2Z" fill="white" />
            </svg>

            <input type="text" placeholder="Search shelf" value={searchQuery} onChange={e => setSearchQuery(e.target.value)} className="w-full max-sm:h-12 pl-9 sm:pl-7 pr-3 py-2 rounded-[100px] bg-[#232533] sm:bg-white/[4%] placeholder:text-base text-base max-sm:placehoder:text-white/40 sm:text-white sm:placeholder:text-xs sm:text-xs focus:outline-none focus:ring-1 focus:ring-[#5069ce]" aria-label="Search shelves" />
          </div>

          {/* Shelves Content */}
          <div className="overflow-y-auto custom-dropdown-scrollbar h-[288px] sm:h-[224px] max-h-[288px] sm:max-h-[224px]">
            {filteredShelves && filteredShelves?.length > 0 ? shelfSelectionVariant === "radio" ? (
          /**
           * Radio variant for shelves:
           */
          <RadioGroup value={selectedShelves[0] || ""} onValueChange={val => handleShelfCheckChange(val, true)} className="gap-0 p-0 sm:divide-y sm:divide-[#bdbdbd]/10">
                  {filteredShelves.map(sh => <div key={sh.id} className="flex items-center hover:bg-[#10111e]/50 gap-3">
                      <label htmlFor={`radio-shelf-${sh.id}`} className="flex w-full py-2 sm:py-4 px-3.5 text-sm font-medium items-center gap-3 cursor-pointer hover:bg-[#10111e]/50 rounded-md">
                        <RadioGroupItem id={`radio-shelf-${sh.id}`} value={sh.id} className="size-[18px]" />
                        <span className="text-sm flex-1">{sh.name}</span>
                      </label>
                    </div>)}
                </RadioGroup>) : filteredShelves.map(sh => {
            const isChecked = selectedShelves?.includes(sh.id);
            return <div key={sh.id} className="flex h-fit w-full bg-[#10111e] sm:bg-[#1d1e2f] items-center gap-1 sm:gap-3 aria-selected:bg-[#26273b] sm:border-r-4 border-r-transparent max-sm:border-none aria-selected:border-r-[#5069ce] rounded-none  leading-5 text-white  aria-selected:text-white">
                      <label htmlFor={`shelf-${sh.id}`} className="sm:space-x-2 hover:bg-[#10111e]/50 py-2 sm:py-4 cursor-pointer flex h-full w-full sm:bg-[#1d1e2f] items-center gap-2 sm:gap-3 aria-selected:bg-[#26273b] max-sm:border-none sm:border-r-4 border-r-transparent aria-selected:border-r-[#5069ce] rounded-none sm:border-b sm:border-b-[#bdbdbd]/10 pl-3.5 sm:leading-5 text-white  aria-selected:text-white">
                        <Checkbox id={`shelf-${sh.id}`} checked={isChecked} className="h-4 w-4 rounded-[4px] sm:rounded-[2px] border-white" onCheckedChange={checked => {
                  if (checked) {
                    setSelectedShelves(prev => [...prev, sh.id]);
                    setUnselectedShelves(prev => prev.filter(id => id !== sh.id));
                  } else {
                    setSelectedShelves(prev => prev.filter(id => id !== sh.id));
                    setUnselectedShelves(prev => [...prev, sh.id]);
                  }
                }} />
                        <span className="text-sm flex-1 font-medium">
                          {sh.name}
                        </span>
                      </label>
                    </div>;
          }) : <div className="p-4 text-center text-sm text-gray-400">
                No shelves found.
              </div>}
          </div>

          <div className="flex max-sm:hidden flex-col gap-2 justify-center max-sm:pt-3 px-3.5">
            <div className="flex items-center justify-between">
              <Button onClick={() => setShowNewShelfInput(true)} type="button" variant="link" className="p-0 text-[#7187E1] flex items-center gap-1.5 underline">
                Add Shelf{" "}
                {!showNewShelfInput && <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="8" cy="8" r="8" fill="#5069CE" />
                    <path d="M8.71429 3.71429C8.71429 3.52485 8.63903 3.34316 8.50508 3.20921C8.37112 3.07526 8.18944 3 8 3C7.81056 3 7.62888 3.07526 7.49492 3.20921C7.36097 3.34316 7.28571 3.52485 7.28571 3.71429V7.28571H3.71429C3.52485 7.28571 3.34316 7.36097 3.20921 7.49492C3.07526 7.62888 3 7.81056 3 8C3 8.18944 3.07526 8.37112 3.20921 8.50508C3.34316 8.63903 3.52485 8.71429 3.71429 8.71429H7.28571V12.2857C7.28571 12.4752 7.36097 12.6568 7.49492 12.7908C7.62888 12.9247 7.81056 13 8 13C8.18944 13 8.37112 12.9247 8.50508 12.7908C8.63903 12.6568 8.71429 12.4752 8.71429 12.2857V8.71429H12.2857C12.4752 8.71429 12.6568 8.63903 12.7908 8.50508C12.9247 8.37112 13 8.18944 13 8C13 7.81056 12.9247 7.62888 12.7908 7.49492C12.6568 7.36097 12.4752 7.28571 12.2857 7.28571H8.71429V3.71429Z" fill="white" />
                  </svg>}
              </Button>

              {showNewShelfInput && <Button onClick={() => setShowNewShelfInput(false)} type="button" variant="link" className="p-0">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.83403 14.1189C4.63472 14.3252 4.52444 14.6016 4.52693 14.8885C4.52942 15.1754 4.6445 15.4498 4.84736 15.6526C5.05022 15.8555 5.32464 15.9706 5.61152 15.9731C5.8984 15.9756 6.17478 15.8653 6.38114 15.666L10.25 11.7971L14.1189 15.666C14.2198 15.7705 14.3405 15.8538 14.474 15.9112C14.6075 15.9685 14.7511 15.9987 14.8964 16C15.0416 16.0012 15.1857 15.9735 15.3202 15.9185C15.4546 15.8635 15.5768 15.7823 15.6795 15.6795C15.7823 15.5768 15.8635 15.4546 15.9185 15.3202C15.9735 15.1857 16.0012 15.0416 16 14.8964C15.9987 14.7511 15.9685 14.6075 15.9112 14.474C15.8538 14.3405 15.7705 14.2198 15.666 14.1189L11.7971 10.25L15.666 6.38114C15.7705 6.28021 15.8538 6.15947 15.9112 6.02598C15.9685 5.8925 15.9987 5.74892 16 5.60364C16.0012 5.45837 15.9735 5.31429 15.9185 5.17983C15.8635 5.04536 15.7823 4.9232 15.6795 4.82047C15.5768 4.71774 15.4546 4.63649 15.3202 4.58148C15.1857 4.52646 15.0416 4.49878 14.8964 4.50004C14.7511 4.5013 14.6075 4.53149 14.474 4.58883C14.3405 4.64617 14.2198 4.72953 14.1189 4.83403L10.25 8.70289L6.38114 4.83403C6.28021 4.72953 6.15947 4.64617 6.02598 4.58883C5.8925 4.53149 5.74892 4.5013 5.60364 4.50004C5.45837 4.49878 5.31429 4.52646 5.17983 4.58148C5.04536 4.63649 4.9232 4.71774 4.82047 4.82047C4.71774 4.9232 4.63649 5.04536 4.58148 5.17983C4.52646 5.31429 4.49878 5.45837 4.50004 5.60364C4.5013 5.74892 4.53149 5.8925 4.58883 6.02598C4.64617 6.15947 4.72953 6.28021 4.83403 6.38114L8.70289 10.25L4.83403 14.1189Z" fill="white" />
                  </svg>
                </Button>}
            </div>

            {showNewShelfInput && <AddNewShelfInput username={username} onAddShelf={shelf => setSelectedShelves(prev => [shelf.id, ...prev])} />}
          </div>
        </div>

        {/* <div className="overflow-y-auto custom-dropdown-scrollbar max-h-[316px]">
          {shelves.map((sh) => {
            const isChecked = selectedShelves.includes(sh.id)
            return (
              <div
                key={sh.id}
                className="flex h-full w-full bg-[#1d1e2f] items-center gap-3 aria-selected:bg-[#26273b] border-r-4 border-r-transparent aria-selected:border-r-[#5069ce] rounded-none  leading-5 text-white  aria-selected:text-white"
              >
                <label
                  htmlFor={`shelf-${sh.id}`}
                  className="space-x-2 hover:bg-[#10111e]/50 py-4 cursor-pointer flex h-full w-full bg-[#1d1e2f] items-center gap-3 aria-selected:bg-[#26273b] border-r-4 border-r-transparent aria-selected:border-r-[#5069ce] rounded-none border-b border-b-[#bdbdbd]/10 pl-3.5 leading-5 text-white  aria-selected:text-white"
                >
                  <Checkbox
                    id={`shelf-${sh.id}`}
                    checked={isChecked}
                    className="h-4 w-4 rounded-[2px] border-white"
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setSelectedShelves((prev) => [...prev, sh.id])
                      } else {
                        setSelectedShelves((prev) =>
                          prev.filter((id) => id !== sh.id)
                        )
                      }
                    }}
                  />
                  <span className="text-sm flex-1 font-medium">{sh.name}</span>
                </label>
              </div>
            )
          })}
         </div> */}
      </TabsContent>

      {/* Footer Buttons */}
      <div className="flex max-sm:hidden justify-end p-3 space-x-2">
        <Button variant="secondary" className="text-base h-[46px] rounded-[8px] font-normal" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" className="text-base font-normal h-[46px] w-fit rounded-[8px] " onClick={handleApply}>
          Apply
        </Button>
      </div>

      <div className={cn("p-5 sm:hidden flex mt-5 justify-end space-x-4", showNewShelfInput && "mt-0")}>
        <Button type="button" variant="secondary" className="px-4 py-2.5 h-fit bg-transparent border border-none rounded-[6px] text-white" onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleApply} type="button" variant="primary" className="px-4 py-2.5 h-fit w-fit bg-[#5069ce] text-white rounded-[6px]">
          Save
        </Button>
      </div>
    </Tabs>;
  return isMobile ? <Drawer open={open} onOpenChange={setOpen} data-sentry-element="Drawer" data-sentry-component="StatusShelvesDropdown" data-sentry-source-file="StatusShelvesDropdown.tsx">
      <DrawerTrigger asChild data-sentry-element="DrawerTrigger" data-sentry-source-file="StatusShelvesDropdown.tsx">{trigger}</DrawerTrigger>

      <DrawerContent className="p-0 bg-[#10111e] text-white outline-none border-none" data-sentry-element="DrawerContent" data-sentry-source-file="StatusShelvesDropdown.tsx">
        {content}
      </DrawerContent>
    </Drawer> : <Popover open={open} onOpenChange={setOpen} data-sentry-element="Popover" data-sentry-component="StatusShelvesDropdown" data-sentry-source-file="StatusShelvesDropdown.tsx">
      <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="StatusShelvesDropdown.tsx">{trigger}</PopoverTrigger>
      <PopoverContent className="p-0 bg-[#1d1e2f] z-[100] w-[246px]  text-white border border-[#bdbdbd]/10 rounded-md" sideOffset={8} data-sentry-element="PopoverContent" data-sentry-source-file="StatusShelvesDropdown.tsx">
        <PopoverArrow className="w-8 h-4 fill-[#1d1e2f]" data-sentry-element="PopoverArrow" data-sentry-source-file="StatusShelvesDropdown.tsx" />
        {content}
      </PopoverContent>
    </Popover>;
}