// src/components/library/CreateShelfDrawer.tsx

import React, { useState } from "react";
import { Drawer, DrawerTrigger, DrawerContent, DrawerHeader, DrawerTitle } from "@/components/ui/drawer"; // Adjust the import path based on your project structure
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Loader2 } from "lucide-react";
interface ModifyShelfDrawerProps {
  action?: "create" | "rename";
  currentShelfName?: string;
  onCancel?: () => void;
  onSave: (shelfName: string) => Promise<void>;
  variant?: "default" | "dialog";
}
const ModifyShelfDrawer: React.FC<ModifyShelfDrawerProps> = ({
  onCancel,
  currentShelfName,
  onSave,
  action = "create",
  variant = "default"
}) => {
  const [open, setOpen] = useState(false);
  const [shelfName, setShelfName] = useState(currentShelfName ?? "");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  // const dialogInputRef = React.useRef<HTMLInputElement | null>(null)
  const drawerInputRef = React.useRef<HTMLInputElement | null>(null);
  React.useEffect(() => {
    // if (dialogInputRef?.current) {
    //   dialogInputRef?.current.focus()
    // } else
    if (variant !== "dialog" && drawerInputRef?.current) {
      drawerInputRef?.current.focus();
    }
  }, [variant]);
  const handleSave = async () => {
    setLoading(true);
    if (!shelfName.trim()) {
      setErrorMessage("Shelf name cannot be empty.");
      return;
    }
    try {
      await onSave(shelfName.trim());
      setShelfName("");
      setErrorMessage("");
      setOpen(false);
      setLoading(false);
    } catch (error) {
      setErrorMessage("Failed to create shelf. Please try again.");
      console.error(error);
    }
    setLoading(false);
  };
  const handleCancel = () => {
    onCancel?.();
    setShelfName("");
    setErrorMessage("");
    setOpen(false);
  };
  if (variant === "dialog") return <div className="flex flex-col p-0">
        <div className="flex h-14 items-center border rounded-[8px] border-[#d2d8f1]/25 ">
          <Input autoFocus={false}
      // ref={dialogInputRef}
      placeholder="Type shelf name..." type="text" className="placeholder:text-white/40 placeholder:text-sm outline-none border-none text-white text-sm rounded-[8px] px-4 py-3" value={shelfName} onChange={e => setShelfName(e.target.value)} />
        </div>
        {errorMessage && <p className="text-red-400 text-sm mt-1">{errorMessage}</p>}
        <div className="flex mt-10 justify-end space-x-4">
          <Button type="button" variant="secondary" className="px-4 py-2.5 h-fit bg-transparent border-none rounded-[6px] text-white" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="button" variant="primary" className="px-4 w-fit h-fit py-2.5 bg-[#5069ce] text-white rounded-[6px]" onClick={handleSave}>
            {loading ? <Loader2 className="animate-spin" /> : action === "rename" ? "Save" : "Add"}
          </Button>
        </div>
      </div>;
  return <div className="lg:hidden px-5" data-sentry-component="ModifyShelfDrawer" data-sentry-source-file="ModifyShelfDrawer.tsx">
      <Drawer open={open} onOpenChange={setOpen} data-sentry-element="Drawer" data-sentry-source-file="ModifyShelfDrawer.tsx">
        <DrawerTrigger asChild data-sentry-element="DrawerTrigger" data-sentry-source-file="ModifyShelfDrawer.tsx">
          <Button variant="secondary" type="button" className="h-full w-full mt-4 rounded-[8px] py-3.5 px-3 text-lg bg-white/[6%] font-semibold text-white lg:hidden" data-sentry-element="Button" data-sentry-source-file="ModifyShelfDrawer.tsx">
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="ModifyShelfDrawer.tsx">
              <path d="M17.2143 10.2857H10.7857V16.7143C10.7857 17.0553 10.6503 17.3823 10.4091 17.6234C10.168 17.8645 9.84099 18 9.5 18C9.15901 18 8.83198 17.8645 8.59086 17.6234C8.34975 17.3823 8.21429 17.0553 8.21429 16.7143V10.2857H1.78571C1.44472 10.2857 1.1177 10.1503 0.876577 9.90914C0.635459 9.66802 0.5 9.34099 0.5 9C0.5 8.65901 0.635459 8.33198 0.876577 8.09086C1.1177 7.84975 1.44472 7.71429 1.78571 7.71429H8.21429V1.28571C8.21429 0.944722 8.34975 0.617695 8.59086 0.376577C8.83198 0.135458 9.15901 0 9.5 0C9.84099 0 10.168 0.135458 10.4091 0.376577C10.6503 0.617695 10.7857 0.944722 10.7857 1.28571V7.71429H17.2143C17.5553 7.71429 17.8823 7.84975 18.1234 8.09086C18.3645 8.33198 18.5 8.65901 18.5 9C18.5 9.34099 18.3645 9.66802 18.1234 9.90914C17.8823 10.1503 17.5553 10.2857 17.2143 10.2857Z" fill="white" data-sentry-element="path" data-sentry-source-file="ModifyShelfDrawer.tsx" />
            </svg>
            <span className="ml-2 text-white" style={{
            filter: "drop-shadow(0px 3.04px 3.04px rgba(0, 0, 0, 0.25))"
          }}>
              Create new custom shelf
            </span>
          </Button>
        </DrawerTrigger>
        <DrawerContent className="p-0 z-[120] rounded-t-[20px] bg-[#10111e] text-white border-none flex flex-col" data-sentry-element="DrawerContent" data-sentry-source-file="ModifyShelfDrawer.tsx">
          <DrawerHeader className="border-b p-6 pb-5 mb-2.5 border-b-black flex justify-between items-center" data-sentry-element="DrawerHeader" data-sentry-source-file="ModifyShelfDrawer.tsx">
            <DrawerTitle className="text-2xl font-bold text-left" data-sentry-element="DrawerTitle" data-sentry-source-file="ModifyShelfDrawer.tsx">
              Create New
            </DrawerTitle>
          </DrawerHeader>
          <div className="flex flex-col p-6 pt-5">
            <div className="flex h-14 items-center border rounded-[8px] border-[#d2d8f1]/25 ">
              <Input ref={drawerInputRef} placeholder="Type shelf name..." type="text" className="placeholder:text-white/40 placeholder:text-sm outline-none border-none text-white text-sm rounded-[8px] px-4 py-3" value={shelfName} onChange={e => setShelfName(e.target.value)} data-sentry-element="Input" data-sentry-source-file="ModifyShelfDrawer.tsx" />
            </div>
            {errorMessage && <p className="text-red-400 text-sm mt-1">{errorMessage}</p>}
            <div className="flex mt-10 justify-end space-x-4">
              <Button type="button" variant="secondary" className="px-4 py-2.5 bg-transparent border-none rounded-[6px] text-white" onClick={handleCancel} data-sentry-element="Button" data-sentry-source-file="ModifyShelfDrawer.tsx">
                Cancel
              </Button>
              <Button type="button" variant="primary" className="px-4 w-fit py-2.5 bg-[#5069ce] text-white rounded-[6px]" onClick={handleSave} data-sentry-element="Button" data-sentry-source-file="ModifyShelfDrawer.tsx">
                {action === "rename" ? "Save" : "Add"}
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </div>;
};
export default ModifyShelfDrawer;